<template>
  <div class="scroll-container" @scroll="handleScroll">
    <headerNav activeIndex="1"></headerNav>
    <div class="homeBody">
        <div class="bannerImg">
          <div class="btnDiv">
            <router-link to="/contact"><el-button type="primary" class="formBtn" >免费咨询</el-button></router-link>
          </div>
        </div>
      <div class="suspended">
        <div class="suspendedDiv">
          <div><img src="../../assets/img/suspended1.svg"/></div>
          <div >
            <div class="suspendedTitle">优质五星售后服务</div>
            <div class="suspendedSpan">专业为您贴心服务</div>
          </div>
        </div>
        <div class="suspendedDiv">
          <div><img src="../../assets/img/suspended2.svg"/></div>
          <div >
            <div class="suspendedTitle">专利技术品效更加</div>
            <div class="suspendedSpan">行业内多个专利加持</div>
          </div>
        </div>
        <div class="suspendedDiv">
          <div><img src="../../assets/img/suspended3.svg"/></div>
          <div >
            <div class="suspendedTitle">500强集团背景保障</div>
            <div class="suspendedSpan">背靠集团，运营无忧</div>
          </div>
        </div>
      </div>
      <div class="products">
        <div class="generalTitle">热门产品</div>
        <div class="generalXian"></div>
        <div class="generalText">面向丰富业务场景的全栈解决方案</div>
        <div class="productsImg">
          <div class="productsImg1">
            <span class="title">链动云</span>
            <span class="text">业财一体化的数字化管理中台</span>
            <router-link to="/liandongyun"> <el-button class="btn">了解详情</el-button></router-link>
          </div>
          <div class="productsImg2">
            <span class="title">链云票</span>
            <span class="text">专为企业开票场景设计</span>
            <router-link to="/lianyunpiao"> <el-button class="btn">了解详情</el-button></router-link>
          </div>
          <div class="productsImg3">
            <span class="title">链捷报</span>
            <span class="text">解决企业费用全流程业务</span>
            <router-link to="/lianjiebao"> <el-button class="btn">了解详情</el-button></router-link>
          </div>
          <div class="productsImg4">
            <span class="title">钱会多</span>
            <span class="text">企业可视化资金管理</span>
            <router-link to="/qianhuiduo"> <el-button class="btn">了解详情</el-button></router-link>
          </div>
          <div class="productsImg5">
            <span class="title">孺子牛</span>
            <span class="text">大宗商品物流供应链数字化平台</span>
            <router-link to="/ruziniu"> <el-button class="btn">了解详情</el-button></router-link>
          </div>
        </div>
      </div>
      <div class="service">
        <div class="generalTitle">咨询定制</div>
        <div class="generalXian"></div>
        <div class="generalText">专业技术服务为您的需求保驾护航</div>
        <div class="serviceImg">
          <div class="carouselLeft" @click="switching(1)"></div>
          <el-carousel ref="carousel" :interval="3000"  arrow="never" :autoplay="autoplayState" style="width:993px; height:436px;">
            <el-carousel-item  style="width:993px; height: 436px">
              <img src="../../assets/img/serviceImg1.png" style="width:993px; height: 436px;"/>
            </el-carousel-item>
            <el-carousel-item  style="width:993px; height: 436px">
              <img src="../../assets/img/serviceImg2.png" style="width:993px; height: 436px;"/>
            </el-carousel-item>
            <el-carousel-item  style="width:993px; height: 436px">
              <img src="../../assets/img/serviceImg3.png" style="width:993px; height: 436px;"/>
            </el-carousel-item>
            <el-carousel-item  style="width:993px; height: 436px">
              <img src="../../assets/img/serviceImg4.png" style="width:993px; height: 436px;"/>
            </el-carousel-item>
            <el-carousel-item  style="width:993px; height: 436px">
              <img src="../../assets/img/serviceImg5.png" style="width:993px; height: 436px;"/>
            </el-carousel-item>
          </el-carousel>
          <div class="carouselRight" @click="switching(2)"></div>
        </div>
      </div>
      <div class="advantages">
        <div class="generalTitle">客户案例</div>
        <div class="generalXian"></div>
        <div class="generalText">已服务客户超过1000+</div>
        <div class="advantagesImg">
          <div class="advantagesDiv">
            <img src="../../assets/img/advantagesImg1.jpg" class="topImg"/>
            <div class="divImg"><img src="../../assets/img/advantagesImg4.png" style="width:177px;height:42px;"/></div>
            <div class="divText">链动云助力湖南建投建材公司数字化转型</div>
            <div class="divlink">查看详情 <i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="advantagesDiv">
            <img src="../../assets/img/advantagesImg2.jpg" class="topImg"/>
            <div class="divImg"><img src="../../assets/img/advantagesImg5.png" style="width:259px;height:50px;"/></div>
            <div class="divText">链动云助力湖南浏阳河饲料集团开启农业数字化转…</div>
            <div class="divlink">查看详情 <i class="el-icon-arrow-right"></i></div>
          </div>
          <div class="advantagesDiv">
            <img src="../../assets/img/advantagesImg3.png" class="topImg"/>
            <div class="divImg"><img src="../../assets/img/advantagesImg6.png" style="width:179px;height:50px;"/></div>
            <div class="divText">链动云服务大汉电子商务有限公司钢贸供应链系统运营</div>
            <div class="divlink">查看详情 <i class="el-icon-arrow-right"></i></div>
          </div>
        </div>
      </div>
      <div class="partners">
        <div class="generalTitle">合作伙伴</div>
        <div class="generalXian"></div>
        <div class="generalText">服务过的客户或合作商超1万+</div>
        <div class="partnersImg">
          <div><img src="../../assets/img/logo1@2x.png"  style="width:179px;height:50px"/></div>
          <div><img src="../../assets/img/logo2@2x.png"  style="width:213px;height:50px"/></div>
          <div><img src="../../assets/img/logo3@2x.png"  style="width:240px;height:50px"/></div>
          <div><img src="../../assets/img/logo4@2x.png" style="width:196px;height:50px"/></div>
          <div><img src="../../assets/img/logo5@2x.png"  style="width:215px;height:40px"/></div>
          <div><img src="../../assets/img/logo6@2x.png"  style="width:215px;height:60px"/></div>
          <div><img src="../../assets/img/logo7@2x.png"  style="width:218px;height:55px"/></div>
          <div><img src="../../assets/img/logo8@2x.png"  style="width:243px;height:50px"/></div>
          <div><img src="../../assets/img/logo9@2x.png"  style="width:198px;height:53px"/></div>
          <div><img src="../../assets/img/logo10@2x.png"  style="width:222px;height:50px"/></div>
          <div><img src="../../assets/img/logo11@2x.png"  style="width:208px;height:50px"/></div>
          <div><img src="../../assets/img/logo12@2x.png"  style="width:236px;height:50px"/></div>
          <div>湖南溪谷贸易</div>
          <div>永州楚材钢铁</div>
          <div>湖南大鑫钢铁</div>
          <div>湖南湘已贸易</div>
          <div>江西欣盛达园</div>
          <div>铖永璨实业</div>
          <div>湖南中寓建材</div>
          <div>湖南大铭</div>
        </div>
      </div>
    </div>
    <joinNav></joinNav>
    <contactNav></contactNav>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import footerNav from "@/components/footerNav.vue"
import { mapState, mapActions } from "vuex";
import joinNav from "@/components/joinNav.vue";
import contactNav from "@/components/contactNav.vue";
export default {
  name:'home',
  components: {
    contactNav,
    joinNav,
    headerNav,
    footerNav
  },
  data() {
    return {
      indexBanner: [],
      autoplayState:false,
    };
  },
  mounted() {
  },
  methods: {
    switching(type){
      if(type == 1){
        this.$refs.carousel.prev()
      }else{
        this.$refs.carousel.next()
      }

    },
    handleScroll(event) {
      if(event.target.scrollTop > 500 && event.target.scrollTop < 1950){
         this.autoplayState = true
      }else{
        this.autoplayState = false
      }
    },
  },


};
</script>
<style>
.el-carousel--horizontal {
  overflow-y: hidden;
}
.el-carousel__indicators{
    position: absolute!important;
    bottom:-16px!important;
}
.el-carousel__button{
  width: 20px!important;
}
</style>
<style lang="scss" scoped>
.scroll-container{
  height: 100vh;
  overflow-y: scroll;
}
.homeBody{
   width:100%;
  .bannerImg{
    width:100%;
    height:397px;
    background:url("../../assets/img/homeBanner.svg") no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .btnDiv{
      width: 1200px;
      position: relative;
      .formBtn{
        width: 180px;
        height: 42px;
        background: #4C6DFD;
        border-radius: 8px;
        position: relative;
        top: 60px;
        border: none;
      }
      .formBtn:hover{
        width: 180px;
        height: 42px;
        background: #4460d3;
        border-radius: 8px;
        position: relative;
        top: 60px;
        border: none;
      }
    }
  }
  .suspended{
    width: 1100px;
    height: 175px;
    box-shadow: 0px 2px 56px 0px rgba(188,205,225,0.43);
    border-radius: 8px;
    margin:0 auto;
    position: relative;
    top: -40px;
    background-color: #FFFFFF;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 0px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .suspendedDiv{
      display: flex;
      align-items: center;
      .suspendedTitle{
        margin-left: 22px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 20px;
        color: #050829;
        line-height: 28px;
        text-align: left;
        font-style: normal;
      }
      .suspendedSpan{
        margin-left: 22px;
        margin-top: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 15px;
        color: #999999;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
      img{
        display: inline-block;
        width:120px;
        height:120px;
        transition: all  .25s ;
        cursor:pointer;
      }
      img:hover{
        transform:translateY(-10px);
      }
    }

  }
  .products{
    width: 1200px;
    margin:0px auto;
    text-align: center;
    .productsImg{
      padding: 30px 0px;
      width:1200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div{
        width: 224px;
        height: 324px;
        transition: all  .25s ;
        cursor:pointer;
        span{
          display:block;
        }
        .title{
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          font-size: 28px;
          line-height: 40px;
          font-style: normal;
          margin-top: 172px;
        }
        .text{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          font-style: normal;
          margin-top: 9px;
        }
        .btn{
          width: 120px;
          height: 36px;
          border-radius: 8px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #2788FE;
          line-height: 10px;
          font-style: normal;
          margin-top: 25px;
          display: none;
        }
      }
      div:hover{
        transform:translateY(-10px);
        .text{
          opacity: 0.59;
        }
        .btn{
          display: inline-block;
        }
      }
      .productsImg1{
        background:url("../../assets/img/productsImg1.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #15456D;
        }
        .text{
          color: #15456D;
        }
      }
      .productsImg1:hover{
        background:url("../../assets/img/productsImg6.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #FFFFFF;
        }
        .text{
          color: #FFFFFF;
        }
      }
      .productsImg2{
        background:url("../../assets/img/productsImg2.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #3f7ac1;
        }
        .text{
          color: #5b8eda;
        }
      }
      .productsImg2:hover{
        background:url("../../assets/img/productsImg7.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #FFFFFF;
        }
        .text{
          color: #FFFFFF;
        }
      }
      .productsImg3{
        background:url("../../assets/img/productsImg3.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #0635B1;
        }
        .text{
          color: #3558B5;
        }
      }
      .productsImg3:hover{
        background:url("../../assets/img/productsImg8.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #FFFFFF;
        }
        .text{
          color: #FFFFFF;
        }
      }
      .productsImg4{
        background:url("../../assets/img/productsImg4.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #2334A8;
        }
        .text{
          color: #4F60D0;
        }
      }
      .productsImg4:hover{
        background:url("../../assets/img/productsImg9.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #FFFFFF;
        }
        .text{
          color: #FFFFFF;
        }
      }
      .productsImg5{
        background:url("../../assets/img/productsImg5.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #008B86;
        }
        .text{
          color: #00A9A2;
        }
      }
      .productsImg5:hover{
        background:url("../../assets/img/productsImg0.png") no-repeat center;
        background-size: 100% 100%;
        .title{
          color: #FFFFFF;
        }
        .text{
          color: #FFFFFF;
        }
      }
    }
  }
  .service{
    margin-top:20px;
    text-align: center;
    .serviceImg{
      margin-top: 30px;
      width: 100%;
      height: 544px;
      background:url("../../assets/img/serviceImg.png") no-repeat center;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .carouselLeft{
        width: 40px;
        height: 40px;
        margin-right: 98px;
        background:url("../../assets/img/carouselLeft.png") no-repeat center;
        background-size: 100% 100%;
        cursor:pointer;
      }
      .carouselRight{
        width: 40px;
        height: 40px;
        margin-left: 98px;
        background:url("../../assets/img/carouselRight.png") no-repeat center;
        background-size: 100% 100%;
        cursor:pointer;
      }
    }
  }
  .advantages{
    background: #F0F9FF;
    height: 640px;
    padding-top: 50px;
    text-align: center;
    .advantagesImg{
      margin:0px auto;
      margin-top: 30px;
      width: 1200px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .advantagesDiv{
        width: 374px;
        height: 419px;
        border-radius:25px;
        background: #FFFFFF;
        cursor: pointer;
        transition: all 0.2s ease-in;
        .topImg{
          width: 100%;
          height: 200px;
          border-radius:25px 25px 0px 0px
        }
        .divImg{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 90px;
          padding-left: 25px;
        }
        .divText{
          width: 298px;
          text-align: left;
          padding-left: 25px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 24px;
          color: #050829;
          line-height: 33px;
           font-style: normal;
        }
        .divlink{
          margin-top: 20px;
          text-align: left;
          padding-left: 25px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #4C6DFD;
          line-height: 20px;
          font-style: normal;
        }
      }
      .advantagesDiv:hover{
        transform: scale(1.01);
        background: #FFFFFF;
        box-shadow: 0px 2px 56px 0px rgba(188,205,225,0.43);
        border-radius: 24px;
      }
    }
  }
  .partners{
    width: 1200px;
    text-align: center;
    margin: 0px auto;
    margin-top: 50px;
    padding-bottom: 40px;
    .partnersImg{
      margin:0px auto;
      margin-top: 20px;
      width: 1200px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 40px;
      div{
        flex: 1;
        min-width:262px;
        max-width:262px;
        height: 117px;
        cursor:pointer;
        background: #FFFFFF;
        box-shadow: 0px 2px 36px 0px rgba(188,205,225,0.38);
        border-radius: 4px;
        transition: all  .3s  ease-in;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 36px;
        color: #000000;
        line-height: 50px;
      }
      div:hover {
        transform: scale(1.15);
      }
    }
  }
}
.generalTitle{
  width: 500px;
  font-weight: 600;
  font-size: 30px;
  color: #050829;
  letter-spacing: 1px;
  font-style: normal;
  margin:0 auto;
  text-align: center;
}
.generalXian{
  //width: 58px;
  //height: 0px;
  //border: 1px solid #4C6DFD;
  //margin: 0px auto;
  //margin-bottom: 30px;
  //margin-top: 8px;
  //text-align: center;
}
.generalText{
  padding-top: 10px;
  width: 500px;
  height: 25px;
  font-family: PingFangSC, PingFang SC;
  font-size: 17px;
  color: #999999;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  margin: 0px auto;
}
</style>
